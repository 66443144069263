import { AwsApiFactory, AwsCompareFaces, AwsCompareFacesFace, AwsCompareFacesResponse } from '@/config/api'
import { fetcherFactory } from '@/config/api/fetcher'
import { AxiosPromise } from 'axios'
import * as Either from 'fp-ts/Either'
import { fromPredicate } from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import { FaceValidatorQuota, Validations } from './CompareFaces.types'

export const compare = (
  faces: AwsCompareFaces,
  awsFactory = fetcherFactory(AwsApiFactory),
): AxiosPromise<AwsCompareFacesResponse> => {
  return awsFactory.compareFaces(faces)
}

export const validate = (
  response: AwsCompareFacesResponse,
  { faceNotFound, tooManyFaces, notTheSameFace }: Validations,
): Either.Either<string, AwsCompareFacesFace[]> => {
  const eitherFaces = pipe(
    response,
    tooManyFaces,
    Either.map((faces) => faces.faces),
    Either.chain(faceNotFound),
    Either.chain(notTheSameFace),
  )

  return eitherFaces
}

export const validations = {
  faceNotFound: fromPredicate<Array<AwsCompareFacesFace>, string>(
    (x) => !(x.length == 0),
    () => 'Nenhuma face correspondente encontrada.',
  ),
  tooManyFaces: fromPredicate<AwsCompareFacesResponse, string>(
    (x) => !x.unmatchedFaces?.length,
    () => 'Faces incongruentes encontradas.',
  ),
  notTheSameFace: ({ quota }: FaceValidatorQuota) =>
    fromPredicate<Array<AwsCompareFacesFace>, string>(
      (faces) => faces.every((face) => face.similarity > quota && face.confidence > quota),
      () => 'A face do documento não corresponde com a face da foto.',
    ),
}

export const faces = {
  validate,
  compare,
  validations,
}
