<template>
  <div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2" id="divCard">
        <b-card
          v-if="autenticando"
          class="mb-0 card-xl text-center d-flex flex-column justify-content-center align-items-center"
        >
          <b-card-title title-tag="h2" class="font-weight-bold mb-2 mt-2"> Aguarde! </b-card-title>

          <b-icon icon="arrow-clockwise" animation="spin" font-scale="4"></b-icon>
        </b-card>

        <b-card
          v-else-if="!chaveVerificada"
          class="mb-0 card-xl text-center d-flex flex-column justify-content-center align-items-center"
        >
          <b-icon icon="exclamation-triangle-fill" class="mt-3" variant="danger" font-scale="3"></b-icon>

          <b-card-title title-tag="h2" class="font-weight-bold mb-2 mt-2"> Alerta! </b-card-title>

          <b-card-text class="mt-4"> Ocorreu um erro ao se autenticar. </b-card-text>
          <b-card-text class="mb-3"> Acesse o aplicativo e tente acessar novamente. </b-card-text>
        </b-card>

        <b-card v-else-if="apresentaTelaInicial" class="mb-0 card-xl">
          <span class="brand-logo">
            <b-img
              src="@/assets/images/logo/Novos/logoCliente.png"
              alt="logo"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
              height="80"
              ref="refPreviewEl"
              rounded
            />
          </span>

          <b-card-title title-tag="h2" class="font-weight-bold text-center mb-1">
            Empréstimo Consignado - {{ sigla }}
          </b-card-title>

          <b-card-text class="mb-2 text-center"> Bem-vindo! Simule seu empréstimo agora. </b-card-text>

          <b-card-text class="mb-2 text-center"> Clique em avançar para continuar. </b-card-text>

          <b-button :disabled="!chaveVerificada" block class="mt-4" variant="primary" id="botaoAvancar" @click="mostraMenu">
            Avançar
          </b-button>
        </b-card>

        <b-card
          v-if="apresentaMenu"
          class="mb-0 card-xl text-center d-flex flex-column justify-content-center align-items-center"
        >
          <b-card-title title-tag="h2" class="font-weight-bold mb-2 mt-2"> Selecione a opção desejada </b-card-title>

          <b-button block class="mt-4" variant="primary" id="botaoRealizarSimulacao" @click="mostraProposta">
            Realizar Simulação
          </b-button>

          <b-button block class="mt-1" variant="primary" id="botaoAcompanharProposta" @click="mostraListaDePropostas">
            Acompanhar Contrato
          </b-button>
        </b-card>

        <b-card
          v-if="apresentaProposta"
          class="mb-0 card-xl text-center d-flex flex-column justify-content-center align-items-center"
          id="cardExterna"
        >
          <gerar-proposta-externa
            id="gerarPropostaExterna"
            ref="componenteGerarPropostaExterna"
            v-if="apresentaProposta"
            @proximaModal="acessaReconhecimentoFacial"
            @finalizaProposta="mostraTelaInicial"
            @retornarAbaInicial="mostraMenu"
            :parametro="parametroSimulacaoProposta"
          />
        </b-card>

        <b-card
          v-if="apresentaDocumentosObrigatorios && habilitaAplicativo.reconhecimentoDocumento"
          class="mb-0 card-xl text-center d-flex flex-column justify-content-center align-items-center"
          id="cardExterna"
        >
          <DocumentosObrigatorios @documentoValidado="validarEnvioDocumentos" />

          <!-- CASO TENHA SELFIE -->
          <b-button
            v-if="habilitaAplicativo.reconhecimentoFacial"
            :title="!documentosValidados ? 'É necessário enviar os documentos para continuar.' : 'Clique para avançar'"
            class="mt-1 buttonVoltar"
            variant="primary"
            :disabled="!documentosValidados"
            id="botaoAvancarReconhecimentoFacial"
            @click="mostraSelfie"
          >
            Próximo
          </b-button>

          <!-- CASO NÃO TENHA SELFIE -->
          <div v-else>
            <b-checkbox
              class="text-start"
              id="termosDoContratoId"
              v-model="termosDoContrato"
              name="Termos do Contrato"
              v-if="termosCondicoes.isActiveTermosCondicoes"
            >
              Eu aceito os
              <b-link @click="abrirModalContrato" class="text-primary">
                termos do contrato.
                <b-icon icon="box-arrow-up-right" aria-hidden="true" />
              </b-link>
            </b-checkbox>

            <b-checkbox
              class="text-start mb-1"
              id="termosPoliticaDePrivacidadeId"
              v-model="termosPoliticaDePrivacidade"
              name="Termos Politica de Privacidade"
              v-if="politicaDePrivacidade.isActivePoliticaPrivacidade"
            >
              Eu aceito os
              <b-link @click="abrirModalPrivacidade" class="text-primary">
                termos da politica de privacidade.
                <b-icon icon="box-arrow-up-right" aria-hidden="true" />
              </b-link>
            </b-checkbox>
          </div>

          <b-button
            v-if="!habilitaAplicativo.reconhecimentoFacial"
            class="mt-1 buttonVoltar"
            :disabled="!termosDoContrato || !termosPoliticaDePrivacidade || loading"
            variant="primary"
            id="botaoFinalizarProposta"
            @click="finalizarProposta"
          >
            Enviar Solicitação
          </b-button>

          <br />

          <!-- BOTÃO VOLTAR -->
          <b-button class="mt-1 buttonVoltar" variant="primary" id="botaoVoltarProposta" @click="mostraProposta">
            Voltar
          </b-button>
        </b-card>

        <b-card
          v-if="apresentaSelfie && habilitaAplicativo.reconhecimentoFacial"
          class="mb-0 card-xl text-center d-flex flex-column justify-content-center"
          id="cardExterna"
        >
          <selfie @selfieValidada="validarReconhecimentoFacial" />

          <!-- CASO TENHA SELFIE -->
          <b-button
            class="mt-1 buttonVoltar"
            variant="primary"
            :disabled="!documentosValidados"
            id="botaoAvancarReconhecimentoFacial"
            @click="mostraDeteccao"
          >
            Próximo
          </b-button>

          <br />

          <!-- BOTÃO VOLTAR -->
          <b-button
            class="mt-1 buttonVoltar"
            variant="primary"
            id="botaoVoltarDocumentosObrigatorios"
            @click="retornaReconhecimentoFacial"
          >
            Voltar
          </b-button>
        </b-card>

        <b-card
          v-if="apresentaDeteccao && habilitaAplicativo.reconhecimentoDocumento && habilitaAplicativo.reconhecimentoFacial"
          class="mb-0 card-xl text-center d-flex flex-column justify-content-center"
          id="cardExterna"
        >
          <modalDetectaRosto :selfie="selfieCapturada" @userWaved="($event) => setUserWaved()"> </modalDetectaRosto>

          <div>
            <b-checkbox
              class="text-start"
              id="termosDoContratoId"
              v-model="termosDoContrato"
              name="Termos do Contrato"
              v-if="termosCondicoes.isActiveTermosCondicoes"
              :disabled="!deteccaoMovimentos"
            >
              Eu aceito os
              <b-link @click="abrirModalContrato" class="text-primary">
                termos do contrato.
                <b-icon icon="box-arrow-up-right" aria-hidden="true" />
              </b-link>
            </b-checkbox>

            <b-checkbox
              class="text-start mb-1"
              id="termosPoliticaDePrivacidadeId"
              v-model="termosPoliticaDePrivacidade"
              name="Termos Politica de Privacidade"
              v-if="politicaDePrivacidade.isActivePoliticaPrivacidade"
              :disabled="!deteccaoMovimentos"
            >
              Eu aceito os
              <b-link @click="abrirModalPrivacidade" class="text-primary">
                termos da politica de privacidade.
                <b-icon icon="box-arrow-up-right" aria-hidden="true" />
              </b-link>
            </b-checkbox>
          </div>

          <b-button
            class="mt-1 buttonVoltar"
            :disabled="!termosDoContrato || !termosPoliticaDePrivacidade || loading"
            variant="primary"
            id="botaoFinalizarProposta"
            @click="finalizarProposta"
          >
            Enviar Solicitação
          </b-button>

          <br />

          <!-- BOTÃO VOLTAR -->
          <b-button class="mt-1 buttonVoltar" variant="primary" id="botaoVoltarDeteccao" @click="retornaDeteccao">
            Voltar
          </b-button>
        </b-card>

        <b-card
          v-if="apresentaListaDePropostas"
          class="mb-0 card-xl text-center d-flex flex-column justify-content-center"
          id="cardExterna"
        >
          <lista-de-propostas @retornarAbaInicial="apresentaMenu" />

          <b-button class="mt-1 buttonVoltar" variant="primary" id="botaoVoltarMenuPelaListaDeProposta" @click="mostraMenu">
            Voltar
          </b-button>
        </b-card>
      </div>
    </div>

    <b-modal id="modalContrato" title="Termos e Condições de Empréstimo" centered>
      <!-- Conteúdo do modal aqui -->
      <div class="text-left" v-html="sanitizedTermosCondicoes"></div>
      <template #modal-footer="{ ok }">
        <b-button variant="secondary" @click="ok()"> Fechar </b-button>
      </template>
    </b-modal>

    <b-modal id="modalPrivacidade" title="Termos da politica de privacidade" centered>
      <!-- Conteúdo do modal aqui -->
      <div class="text-left" v-html="sanitizedPoliticaDePrivacidade"></div>
      <template #modal-footer="{ ok }">
        <b-button variant="secondary" @click="ok()"> Fechar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import modalDetectaRosto from '@/views/components/modalDetectaRosto.vue'
  import DocumentosObrigatorios from '@/views/components/reconhecimentoFacial/documentosObrigatorios.vue'
  import Selfie from '@/views/components/reconhecimentoFacial/selfie.vue'
  import Swal from 'sweetalert2/dist/sweetalert2.js'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'
  import Ripple from 'vue-ripple-directive'
  import GerarPropostaExterna from './components/gerarPropostaExterna.vue'
  import listaDePropostas from './components/ListaDePropostas.vue'
  import DOMPurify from 'dompurify'

  export default {
    components: {
      GerarPropostaExterna,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      DocumentosObrigatorios,
      Selfie,
      listaDePropostas,
      modalDetectaRosto,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        loading: false,
        parametroSimulacaoProposta: {
          origem: 'Aplicativo',
          habilitaReconhecimentoFacialOuDocumentacao: false,
          habilitaAplicativoReconhecimentoFacial: false,
          habilitaAplicativoReconhecimentoDocumento: false,
        },
        sigla: '',
        url: '',
        userData: null,
        dadosProposta: {},
        idRegistroReconhecimentoFacial: null,
        termosDoContrato: false,
        termosPoliticaDePrivacidade: false,

        // Navegação
        apresentaTelaInicial: true, //Tela inicial
        apresentaMenu: false, //Menu
        apresentaProposta: false, //Proposta
        apresentaDocumentosObrigatorios: false, //Documentos Obrigatórios
        apresentaSelfie: false, //Selfie
        apresentaDeteccao: false, //Deteccao
        apresentaListaDePropostas: false, //Lista de Propostas

        autenticando: false,
        chaveVerificada: false,
        habilitaAplicativo: {
          reconhecimentoFacial: false,
          reconhecimentoDocumento: false,
        },
        termosCondicoes: {
          isActiveTermosCondicoes: false,
          textoTermosCondicoes: false,
        },
        politicaDePrivacidade: {
          isActivePoliticaPrivacidade: false,
          textoPoliticaPrivacidade: false,
        },

        documentosValidados: false,
        selfieValidada: false,
        statusTipoCalculo: [],
        optionsFormaCalculo: [
          { id: 'formaCalculoValorDoEmprestimo', value: 'valorDoEmprestimo', text: 'Valor do Empréstimo' },
          { id: 'formaCalculoValorDaParcela', value: 'valorDaParcela', text: 'Valor da Parcela' },
        ],
        deteccaoMovimentos: false,
        selfieCapturada: {},
      }
    },
    async mounted() {
      await this.autenticarTomador()
    },
    methods: {
      sweetAlert(msg) {
        Swal.fire({
          title: msg,
          timer: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(false)
          },
          allowOutsideClick: false, // Bloqueia o fechamento ao clicar fora do SweetAlert
        })
      },
      setUserWaved() {
        this.deteccaoMovimentos = true
        this.$message.success('Detecção de movimentos realizado com sucesso, por favor aceite os termos!')
      },
      abrirModalContrato() {
        this.$bvModal.show('modalContrato')
      },
      abrirModalPrivacidade() {
        this.$bvModal.show('modalPrivacidade')
      },
      async autenticarTomador() {
        localStorage.removeItem('userData')
        this.url = String(window.location.search).substring(7)
        this.autenticando = true

        useJwt
          .GetloginTomador({ chave: this.url })
          .then((response) => {
            this.sigla = response.data.userData.sigla
            const userData = response.data.userData
            this.chaveVerificada = true
            this.autenticando = false

            useJwt.setToken(response.data.accessToken)
            useJwt.setRefreshToken(response.data.refreshToken)
            this.userData = userData
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)
            this.habilitaAplicativo = {
              reconhecimentoFacial: this.userData?.Instituto?.habilitaAplicativoReconhecimentoFacial,
              reconhecimentoDocumento: this.userData?.Instituto?.habilitaAplicativoReconhecimentoDocumento,
            }
            this.termosCondicoes = {
              isActiveTermosCondicoes: this.userData?.Instituto?.habilitaAplicativoTermosCondicoes,
              textoTermosCondicoes: this.userData?.Instituto?.TextoAplicativoTermosCondicoes,
            }
            this.politicaDePrivacidade = {
              isActivePoliticaPrivacidade: this.userData?.Instituto?.habilitaAplicativoPoliticaPrivacidade,
              textoPoliticaPrivacidade: this.userData?.Instituto?.TextoAplicativoPoliticaPrivacidade,
            }

            this.parametroSimulacaoProposta = {
              ...this.parametroSimulacaoProposta,
              habilitaReconhecimentoFacialOuDocumentacao:
                this.habilitaAplicativo.reconhecimentoFacial || this.habilitaAplicativo.reconhecimentoDocumento,
              habilitaAplicativoReconhecimentoFacial: this.habilitaAplicativo.reconhecimentoFacial,
              habilitaAplicativoReconhecimentoDocumento: this.habilitaAplicativo.reconhecimentoDocumento,
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao validar o acesso. Tente acessar novamente.')
            this.chaveVerificada = false
            this.autenticando = false
          })
      },
      mostraTelaInicial() {
        this.apresentaTelaInicial = true

        this.apresentaMenu = false
        this.apresentaProposta = false
        this.apresentaDocumentosObrigatorios = false
        this.apresentaSelfie = false
        this.apresentaDeteccao = false
        this.apresentaListaDePropostas = false
      },
      mostraMenu() {
        this.apresentaMenu = true

        this.apresentaTelaInicial = false
        this.apresentaProposta = false
        this.apresentaDocumentosObrigatorios = false
        this.apresentaSelfie = false
        this.apresentaDeteccao = false
        this.apresentaListaDePropostas = false
      },
      mostraProposta() {
        this.apresentaProposta = true

        this.apresentaMenu = false
        this.apresentaTelaInicial = false
        this.apresentaDocumentosObrigatorios = false
        this.apresentaSelfie = false
        this.apresentaDeteccao = false
        this.apresentaListaDePropostas = false
      },
      mostraListaDePropostas() {
        this.apresentaListaDePropostas = true

        this.apresentaMenu = false
        this.apresentaTelaInicial = false
        this.apresentaDocumentosObrigatorios = false
        this.apresentaSelfie = false
        this.apresentaDeteccao = false
        this.apresentaProposta = false
      },
      mostraDocumentosObrigatorios(dadosProposta) {
        // Salva dados da proposta
        this.dadosProposta = dadosProposta

        this.apresentaDocumentosObrigatorios = true

        this.apresentaMenu = false
        this.apresentaTelaInicial = false
        this.apresentaProposta = false
        this.apresentaSelfie = false
        this.apresentaDeteccao = false
        this.apresentaListaDePropostas = false
      },
      mostraSelfie(idRegistroReconhecimentoFacial, skipDocs = false) {
        if (!this.documentosValidados && !skipDocs) {
          this.$message.error('É necessário enviar os documentos para continuar.')
          return
        }

        // Armazena registro de reconhecimento facial
        this.idRegistroReconhecimentoFacial = idRegistroReconhecimentoFacial

        this.apresentaSelfie = true

        this.apresentaMenu = false
        this.apresentaTelaInicial = false
        this.apresentaProposta = false
        this.apresentaDocumentosObrigatorios = false
        this.apresentaListaDePropostas = false
      },
      mostraDeteccao() {
        this.apresentaSelfie = false
        this.apresentaDeteccao = true
        this.apresentaMenu = false
        this.apresentaTelaInicial = false
        this.apresentaProposta = false
        this.apresentaDocumentosObrigatorios = false
        this.apresentaListaDePropostas = false
        this.deteccaoMovimentos = false
        this.termosDoContrato = false
        this.termosPoliticaDePrivacidade = false
      },
      acessaReconhecimentoFacial(item) {
        if (this.habilitaAplicativo.reconhecimentoDocumento) this.mostraDocumentosObrigatorios(item)
        else if (this.habilitaAplicativo.reconhecimentoFacial) this.mostraSelfie(item, true)
        else this.mostraTelaInicial()
      },
      retornaReconhecimentoFacial(item) {
        if (this.habilitaAplicativo.reconhecimentoDocumento) this.mostraDocumentosObrigatorios(item)
        else this.mostraProposta(item)
      },
      retornaDeteccao(item) {
        this.dadosProposta = item
        this.apresentaSelfie = true
        this.apresentaMenu = false
        this.apresentaTelaInicial = false
        this.apresentaProposta = false
        this.apresentaDeteccao = false
        this.apresentaListaDePropostas = false
      },
      validarEnvioDocumentos() {
        this.documentosValidados = true
      },
      validarReconhecimentoFacial(selfie) {
        this.selfieCapturada = selfie
        this.selfieValidada = true
      },
      finalizarProposta() {
        if (!this.selfieValidada && this.habilitaAplicativo.reconhecimentoFacial) {
          this.$message.error('É necessário validar a selfie para finalizar o contrato.')
          return
        }

        if (!this.documentosValidados && this.habilitaAplicativo.reconhecimentoDocumento) {
          this.$message.error('É necessário enviar os documentos para finalizar o contrato.')
          return
        }

        if (
          !this.deteccaoMovimentos &&
          this.habilitaAplicativo.reconhecimentoDocumento &&
          this.habilitaAplicativo.reconhecimentoFacial
        ) {
          this.$message.error('É necessário realizar o procedimento de deteccção de movimentos.')
          return
        }

        this.sweetAlert(`Aguarde... Enviando solicitação.`)
        this.salvarContratoExterno(this.dadosProposta)
      },
      salvarContratoExterno() {
        this.loading = true
        this.dadosProposta.origem = 'Aplicativo'
        useJwt
          .post('proposta/geraContratoExterno', this.dadosProposta)
          .then(() => {
            this.$swal({
              title: 'Atenção!',
              text: 'Contrato gerado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {
              this.loading = !this.loading
              this.$message.success('Contrato gerado com sucesso!')
              this.mostraTelaInicial()
            })
          })
          .catch((error) => {
            this.loading = false
            console.error(error)
            this.$message.error('Erro ao salvar o contrato!')
          })
      },
    },
    beforeDestroy() {
      localStorage.removeItem('userData')
    },
    computed: {
      sanitizedTermosCondicoes() {
        return DOMPurify.sanitize(this.termosCondicoes.textoTermosCondicoes)
      },
      sanitizedPoliticaDePrivacidade() {
        return DOMPurify.sanitize(this.politicaDePrivacidade.textoPoliticaPrivacidade)
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  div.cardExterna div#gerarSimulacaoProposta ul[role='tablist'],
  div.cardExterna div#gerarSimulacaoProposta div.wizard-progress-with-circle {
    display: none;
  }

  h3 {
    margin-bottom: unset;
  }

  .referencia {
    top: -1em;
    right: -0.3em;
    position: relative;
    font-size: 0.5em;
    background-color: white;
    color: var(--primary);
    border-radius: 50%;
    width: 1.7em;
    height: 1.7em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .valorParcela {
    position: relative;
    white-space: nowrap;
  }

  .tituloParcela,
  .tituloTaxa {
    color: #d3d3d3;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
  }

  .valorParcela,
  .valorTaxa {
    color: #fff;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .custom-line {
    border-color: #969696;
    margin: inherit;
    margin: 8px;
  }

  .dark-layout .custom-line {
    border-color: #f5f5f5;
  }

  .textAlt {
    font-size: 0.9rem;
    color: #969696;
  }

  .dark-layout .textAlt {
    color: #f5f5f5;
  }

  .text-titulo {
    font-size: 1.3rem;
  }

  .text-valor {
    color: var(--primary);
    margin-bottom: unset;
    margin-top: 13px;
  }

  .container-valor {
    background-color: #f5f5f5;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 0;
    height: 210px;
    text-align: center;
  }

  .dark-layout .container-valor {
    background-color: #273357;
  }

  .container-resultado {
    background-color: var(--primary);
    border-radius: 0 0 10px 10px;
    padding: 15px;
    color: #ffffff;
    height: 100px;
  }

  .container-valor,
  .container-resultado {
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
  }

  .container-valor {
    background-color: #f5f5f5;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 0px;
    margin-bottom: 0;
    height: 270px;
  }

  .container-resultado {
    background-color: var(--primary);
    border-radius: 0 0 10px 10px;
    padding: 15px;
    color: #ffffff;
    height: 140px;
  }

  .textConsignado {
    color: var(--primary);
    font-weight: bolder;
    font-size: 1.5rem;
  }

  [dir='ltr'] .modal .modal-header {
    justify-content: center;
  }

  .corLinhaSlider {
    background-color: #016b3a;
  }
  .modalservidoranalise > .modal-dialog > .modal-content > .modal-header {
    background-color: #f1c40f !important;
    justify-content: center;
  }

  .modalservidorreprovado > .modal-dialog > .modal-content > .modal-header {
    background-color: #da2228 !important;
    justify-content: center;
  }

  .modalservidortodoReprovado > .modal-dialog > .modal-content > .modal-header {
    background-color: #da2228 !important;
    justify-content: center;
  }

  #divCard {
    max-width: 700px;
  }

  #radiosTipoDeContrato > label {
    border: 1px solid var(--primary) !important;
  }

  #wrap {
    padding-top: 25px !important;
  }

  .buttonVoltar {
    height: 40px !important;
    font-size: 14px !important;
    border-radius: 17px !important;
    width: 50vw !important;
    min-width: 15vw !important;
    margin: 5px 0 !important;
  }
</style>
