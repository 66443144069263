import useJwt from '@/auth/jwt/useJwt'

export const createAnexoRegistroReconhecimentoFacial = async (data: any) => {
  return useJwt.post(`reconhecimentoFacial/createAnexoRegistroReconhecimentoFacial`, {
    foto: data.foto,
    nome: data.nome,
    tipo: data.tipo,
    registroReconhecimentoFacialId: data.registroReconhecimentoFacialId,
    anexoReconhecimentoFacialId: data.anexoReconhecimentoFacialId,
    ocr: data.ocr,
    lixeira: data.lixeira || false,
    institutoId: data.institutoId,
    cpf: data.cpf,
  })
}

export const deleteAnexoRegistroReconhecimentoFacial = async (id: number) => {
  return useJwt.delete(`reconhecimentoFacial/deleteAnexoRegistroReconhecimentoFacial`, id)
}

export const getReconhecimentoFacial = async (institutoId: number, cpf: string, includeAnexo: boolean) => {
  return useJwt.get(
    `reconhecimentoFacial/getReconhecimentoFacial?institutoId=${institutoId}&cpf=${cpf}&includeAnexo=${includeAnexo}`,
  )
}

export const createRegistroReconhecimentoFacial = async (dados: any) => {
  return useJwt.post(`reconhecimentoFacial/createRegistroReconhecimentoFacial`, dados)
}

export const VerificaRostoDocumentoAnexadoAWS = async (base64: any) => {
  return useJwt.post(`reconhecimentoFacial/VerificaRostoDocumentoAnexadoAWS`, {
    base64: base64,
  })
}

export const VerificaRostoImagem = async (dataUrl: any) => {
  return useJwt.post(`reconhecimentoFacial/VerificaRostoImagem`, dataUrl)
}
