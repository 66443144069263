<template>
  <div>
    <b-card>
      <h4
        style="
          display: flex;
          justify-content: center;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 25px;
          padding: 10px 8px 8px 15px;
        "
        class="rounded bg-primary text-white"
      >
        Anexar documentos
      </h4>
      <validation-observer ref="dadosDocsRules" tag="form">
        <span v-if="!this.documentsListIsZero()" class="d-block mt-1 mb-1"
          >São aceitos documentos: .jpg, .heic, .png ou .pdf</span
        >
        <!-- <span class="d-block mt-1 mb-1">.jpg, .heic, .png ou .pdf</span> -->
        <b-row>
          <b-col md="9" class="mb-1" v-if="!this.documentsListIsZero()">
            <b-form-select
              id="tiposDocumentos"
              value-field="id"
              text-field="nome"
              v-model="dados.tipoDocumento"
              :options="tiposDocumentosFiltrados"
            >
            </b-form-select>
          </b-col>
          <b-col md="3" class="mb-1" v-if="!this.documentsListIsZero()">
            <template>
              <b-dropdown variant="link" no-caret toggle-class="p-0" dropright>
                <template #button-content>
                  <b-button
                    block
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    title="Este botão contém as opções disponíveis para este registro"
                  >
                    <feather-icon class="mr-1" :icon="`UploadIcon`" title="" />
                    {{ !loading ? ' Selecione' : '' }}
                    <b-spinner small v-if="loading" />
                  </b-button>
                </template>
                <b-dropdown-item @click="tirarFotoDoc()">
                  <feather-icon :icon="'CameraIcon'" class="mr-50" />
                  <span class="align-middle ml-50">Tirar foto</span>
                </b-dropdown-item>
                <b-dropdown-item @click="selecionarArquivo()">
                  <feather-icon :icon="'HardDriveIcon'" class="mr-50" />
                  <span class="align-middle ml-50">Anexar arquivo</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <canvas ref="refCanvasEl" style="display: none !important"></canvas>
            <img
              ref="refPreviewEl"
              class="img-fluid rounded"
              style="max-height: 320px; display: none !important"
              :alt="dados.foto.alt || 'Imagem de usuário'"
            />
            <b-form-file
              v-model="refInputEl"
              ref="refInputEl"
              accept=".pdf, .heic, .jpeg, .jpg, .png"
              @change="getFileDoc"
              style="display: none"
            />
          </b-col>
        </b-row>
        <div style="display: auto" id="divListaDocsAnexados">
          <b-form ref="form" class="repeater-form" @submit.prevent="repeateAgain">
            <!-- Row Loop -->
            <b-row v-for="(item, index) in docAnexados" :id="item.id" :key="item.id" ref="row">
              <!-- Nome -->
              <b-col md="6">
                <b-form-group label="Tipo do Documento" label-for="item-nome">
                  <div class="disabled">{{ item.nome }}</div>
                </b-form-group>
              </b-col>

              <b-col>
                <!-- Remove Button -->
                <b-button
                  v-ripple.300="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2 mr-1"
                  @click="removeItem(index, item)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Excluir</span>
                </b-button>
                <!-- Preview Foto Button -->
                <b-button
                  v-ripple.300="'rgba(440, 199, 111, 0.15)'"
                  variant="outline-info"
                  class="mt-0 mt-md-2 mr-1"
                  @click="showFotoPreview(item)"
                >
                  <feather-icon icon="EyeIcon" class="mr-25" />
                  <span>Visualizar</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
          </b-form>
        </div>
      </validation-observer>
    </b-card>

    <b-modal
      id="modal-foto"
      ref="modal-foto"
      centered
      size="sm"
      title="Captura de Foto"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      style="position: Absolute"
      @hidden="stopCameraStream"
      @close="stopCameraStream"
    >
      <div class="camera-canvas">
        <video v-if="isCameraOpen" ref="camera" :width="canvasWidth" :height="canvasHeight" autoplay></video>
        <canvas v-show="!isCameraOpen" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
      </div>
      <template>
        <b-button size="sm" variant="primary" v-show="isCameraOpen" @click="capture()"> Tirar Foto </b-button>
        <b-button size="sm" variant="success" v-show="!isCameraOpen" @click="checkTwoConfirm()" :disabled="loading">
          Confirmar Foto
        </b-button>
        <b-button size="sm" variant="danger" @click="toggleCamera()" v-show="!isCameraOpen"> Limpar Foto </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-previewFoto"
      ref="modal-previewFoto"
      centered
      size="lg"
      :title="titlePreviewModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      style="position: relative"
      @close="closeModalPreviewAnexoDoc"
    >
      <div class="camera-canvas">
        <img :src="imagemPreviewModal" style="max-width: 100%; max-height: 100%" :alt="dados.foto.alt || 'Imagem de usuário'" />
      </div>
    </b-modal>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    BSpinner,
    BFormFile,
  } from 'bootstrap-vue'
  import { ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import { TabContent } from 'vue-form-wizard'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Swal from 'sweetalert2/dist/sweetalert2.js'
  import {
    createAnexoRegistroReconhecimentoFacial,
    deleteAnexoRegistroReconhecimentoFacial,
    getReconhecimentoFacial,
    createRegistroReconhecimentoFacial,
    VerificaRostoDocumentoAnexadoAWS,
    VerificaRostoImagem,
  } from './utils/reconhecimentoFacialAnexos'
  import { fileToBase64 } from './utils/readBase64'
  import { convertHeicToJpeg } from './utils/convert-imagem/convert-jpeg'
  import { compactImagem } from './utils/compact-itens/compact-Imagem.ts'
  import { compactPDF } from './utils/compact-itens/compact-PDF.ts'
  import { heightTransition } from '@core/mixins/ui/transition'
  import { getMediaError } from './utils/getMediaError'

  export default {
    components: {
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BButton,
      BRow,
      BCol,
      BCard,
      BDropdown,
      BDropdownItem,
      BModal,
      ValidationObserver,
      ToastificationContent,
      BSpinner,
      TabContent,
      BFormFile,
      convertHeicToJpeg,
    },
    directives: {
      Ripple,
      'b-modal': VBModal,
    },
    mixins: [heightTransition],
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,

        fileIndividual: null,
        refInputEl: null,

        titlePreviewModal: '',
        imagemPreviewModal: '',

        dados: {},
        reconhecimentoFacial: {
          anexoRegistro: [],
        },

        docAnexados: [],
        tiposDocumentos: [],
        tiposDocumentosFiltrados: [],

        canvasHeight: 255,
        canvasWidth: 340,

        isCameraOpen: false,
        showBotaRecad: true,
        loading: false,
      }
    },
    async created() {
      this.dados = this.userData
      this.iniciarReconhecimentoFacial()
    },
    methods: {
      documentsListIsZero() {
        return this.tiposDocumentosFiltrados.length > 0 ? false : true
      },
      sweetAlert(msg) {
        Swal.fire({
          title: msg,
          timer: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(false)
          },
          allowOutsideClick: false, // Bloqueia o fechamento ao clicar fora do SweetAlert
        })
      },
      async iniciarReconhecimentoFacial() {
        const reconhecimentoFacial = await getReconhecimentoFacial(this.dados.institutoId, this.dados.cpf, true)
        if (reconhecimentoFacial.data.length == 0) {
          const dadosSegurado = {
            institutoId: this.dados.institutoId,
            cpf: this.dados.cpf,
            nome: this.dados.nomeCompleto,
            selfie: null,
            docAnexados: null,
          }

          const reconhecimentoFacialPost = await createRegistroReconhecimentoFacial(dadosSegurado)
          this.reconhecimentoFacial = reconhecimentoFacialPost.data
        } else {
          this.reconhecimentoFacial = reconhecimentoFacial.data[0]
        }

        if (this.reconhecimentoFacial) await this.addAppendices(this.reconhecimentoFacial)
      },
      async addAppendices(reconhecimentoFacial) {
        // docAnexados: documentos ja anexados pelo servidor
        // tiposDocumentos: tipos de documentos que o servidor pode anexar
        // tiposDocumentosFiltrados: tipos de documentos que o servidor pode anexar e que ainda não foram anexados

        await this.carregarListaOpcoesDocumentos()
        this.docAnexados =
          reconhecimentoFacial?.anexoRegistroReconhecimentoFacial.filter((doc) => {
            return this.tiposDocumentos?.find((tDoc) => tDoc.nome === doc.nome)
          }) ?? []

        this.tiposDocumentosFiltrados = this.tiposDocumentosFiltrados.filter(
          (doc) => this.docAnexados?.find((annex) => annex.nome == doc.nome) == undefined,
        )
      },
      async carregarListaOpcoesDocumentos() {
        const items = [
          {
            id: '2',
            nome: 'DOCUMENTO DE IDENTIFICAÇÃO OFICIAL COM FOTO - FRENTE',
          },
          {
            id: '3',
            nome: 'DOCUMENTO DE IDENTIFICAÇÃO OFICIAL COM FOTO - VERSO',
          },
        ]
        this.tiposDocumentos = [...items]
        this.tiposDocumentosFiltrados = [...items]
      },
      async readFileAsync(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()

          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsArrayBuffer(file)
        })
      },
      async checkDocSize(anexo, extensao) {
        if (anexo.size > 5242880) {
          if (extensao === '.pdf') {
            anexo = await compactPDF(anexo)
          } else if (extensao === '.jpeg' || extensao === '.jpg' || extensao === '.png') {
            anexo = await compactImagem(anexo)
          }

          if (anexo?.size > 5242880 || anexo === undefined) {
            this.$message.info('O tamanho do documento deve ser menor que 5MB!')
            this.loading = false
            return false
          }
        }
        return true
      },
      async convertDocument(anexo, extensao) {
        let base64
        if (extensao === '.heic' || extensao === '.HEIC') {
          try {
            anexo = await convertHeicToJpeg(anexo)
            base64 = await fileToBase64(anexo)
          } catch (error) {
            this.$message.info("Erro ao converter imagem '.heic' para '.jpeg,' tente anexar uma imagem em outro formato.")
            console.error('error', e)
            this.loading = !this.loading
          }
        } else if (anexo?.type == 'application/pdf') {
          try {
            // Convert canvas element to base64 encoded image
            base64 = this.$refs.refCanvasEl.toDataURL()
            // Clear canvas after setting image to base64
            this.$refs.refCanvasEl.getContext('2d').clearRect(0, 0, this.$refs.refCanvasEl.width, this.$refs.refCanvasEl.height)
          } catch (e) {
            this.$toast(
              {
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Atenção`,
                  icon: 'XSquareIcon',
                  variant: 'danger',
                  text: `O arquivo anexado não é um PDF válido.`,
                },
              },
              {
                position: 'top-right',
                timeout: 8000,
              },
            )
            console.error('error', e)
            this.loading = !this.loading
          }
        } else {
          base64 = await fileToBase64(anexo)
        }
        return base64
      },
      async createAnexoRegistro(aux, base64) {
        const res = await createAnexoRegistroReconhecimentoFacial(aux).catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Atenção`,
                icon: 'XSquareIcon',
                variant: 'danger',
                // text: `Falha ao tentar validar o  documento, favor verifique se o documento anexado realmente é um documento oficial com foto.`,
                text: 'Erro ao tentar anexar o documento. Tente novamente mais tarde.',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          console.error(error)
          this.loading = !this.loading
          Swal.close()
        })

        if (!res.data.success && !res.data.id) {
          this.$message.error(res.data?.msg ?? 'Erro ao salvar o documento. Por favor tente novamente.')
          this.loading = !this.loading
          Swal.close()
          return
        }
        this.repeateAgain(base64)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Atenção`,
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: `Documento validado e anexado com sucesso!`,
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
        this.loading = !this.loading
        Swal.close()
      },
      async validFaceInDocument(base64) {
        const response = await VerificaRostoDocumentoAnexadoAWS(base64).catch((erro) => {
          this.$toast(
            {
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Atenção`,
                icon: 'XSquareIcon',
                variant: 'danger',
                text: 'Falha ao tentar validar o documento anexado, tente novamente mais tarde.',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          this.loading = false
          console.error(erro)
          Swal.close()
        })

        if (!response.data.success) {
          // msg de erro
          this.$toast(
            {
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Atenção`,
                icon: 'XSquareIcon',
                variant: 'danger',
                text: !response.data.success
                  ? `O(A) Arquivo/Foto Anexado(a) não possui seu rosto. Favor selecione novamente o arquivo.`
                  : response.data.msg,
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          this.loading = false
          Swal.close()
          return false
        }
        return true
      },
      async getFileDoc(e, webcam = false) {
        this.loading = true
        const delayed = 500
        setTimeout(async () => {
          let anexo = !webcam ? e.target.files[0] : e
          const nomeArquivo = anexo.name
          const extensao = nomeArquivo.substr(nomeArquivo.lastIndexOf('.'))
          if (!(await this.checkDocSize(anexo, extensao))) {
            return
          }

          let idTiposDocumentos = this.dados.tipoDocumento
          let item = this.tiposDocumentos.filter(function (ele) {
            return ele.id == idTiposDocumentos
          })

          const base64 = await this.convertDocument(anexo, extensao)
          item[0].foto = base64

          this.sweetAlert(`Aguarde... Estamos validando o arquivo anexado.`)
          const isDocumentFront = item[0].nome.includes('FRENTE')
          if (isDocumentFront) {
            const response = await this.validFaceInDocument(base64)
            //doc frente invalido
            if (!response) {
              return
            }
          }

          await this.createAnexoRegistro(
            {
              foto: item[0].foto,
              nome: item[0].nome,
              tipo: isDocumentFront ? 2 : 3,
              registroReconhecimentoFacialId: this.reconhecimentoFacial.id,
              institutoId: this.userData?.institutoSelecionado ?? this.dados?.institutoId,
              cpf: this.dados.cpf,
            },
            base64,
          )

          this.fileIndividual = null
        }, delayed)

        setTimeout(() => {
          this.refInputEl = null
        }, delayed * 2)
      },
      closeModalPreviewAnexoDoc() {
        this.imagemPreviewModal = ''
        this.$refs['modal-previewFoto'].hide()
      },
      removerTipoDocumentoFiltrado(id) {
        let i = this.tiposDocumentosFiltrados.map((item) => item?.id).indexOf(id) // find index of your object
        this.tiposDocumentosFiltrados.splice(i, 1)
      },
      showFotoPreview(item) {
        if (!item.foto && item.s3url) {
          this.openModalPreviewFoto(item.s3url)
        } else if (item.foto && !item.s3url) {
          const str = item.foto.split(',')
          const cabecalho = str[0]
          if (cabecalho == 'data:application/pdf;base64') {
            this.openPdfWindow(item)
          } else {
            this.openModalPreviewFoto(item['foto'])
          }
        }
      },
      openModalPreviewFoto(doc) {
        this.imagemPreviewModal = doc
        this.$refs['modal-previewFoto'].show()
      },
      openPdfWindow(doc) {
        let pdfWindow = window.open('')
        pdfWindow.document.write(
          '<html><head><title>' +
            doc.nome +
            "</title></head><body><iframe title='Documento prova de vida'  width='100%' height='100%' src='" +
            encodeURI(doc['foto']) +
            "'></iframe></body></html>",
        )
      },
      repeateAgain(dataUrl) {
        let idTiposDocumentos = this.dados.tipoDocumento
        let item = this.tiposDocumentos.filter(function (ele) {
          return ele.id == idTiposDocumentos
        })
        item[0].foto = dataUrl
        this.docAnexados.push(item[0])
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        })

        this.removerTipoDocumentoFiltrado(this.dados.tipoDocumento)
        this.dados.tipoDocumento = ''
      },
      removeItem(index, item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir o documento?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await deleteAnexoRegistroReconhecimentoFacial(item.id)
            this.tiposDocumentosFiltrados.push(this.tiposDocumentos.find((tDoc) => tDoc.nome == this.docAnexados[index].nome))
            this.docAnexados.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
            this.dados.tipoDocumento = ''
          }
        })
      },
      selecionarArquivo() {
        if (this.dados.tipoDocumento === undefined || this.dados.tipoDocumento == '') {
          this.$swal({
            title: 'Atenção!',
            text: 'Favor Selecionar o Tipo do Documento',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$refs.refInputEl.$el.childNodes[0].click()
        }
      },

      //Funções tirar foto do documento
      tirarFotoDoc() {
        if (this.dados.tipoDocumento === undefined || this.dados.tipoDocumento == '') {
          this.$swal({
            title: 'Atenção!',
            text: 'Favor Selecionar o Tipo do Documento',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.toggleCamera()
          this.$refs['modal-foto'].show()
        }
      },
      toggleCamera() {
        if (this.isCameraOpen) {
          this.isCameraOpen = false
          this.stopCameraStream()
        } else {
          this.isCameraOpen = true
          this.startCameraStream()
        }
      },
      startCameraStream() {
        const constraints = (window.constraints = {
          audio: false,
          video: true,
        })
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.$refs.camera.srcObject = stream
          })
          .catch((error) => {
            const erroMensage = getMediaError(error)
            console.error(error, erroMensage)
            this.$message.error('Desculpe mas seu navegador não tem suporte para capturar foto!')
          })
      },
      stopCameraStream() {
        this.isCameraOpen = false
        var tracks
        if (this.$refs.camera) {
          tracks = this.$refs.camera.srcObject.getTracks()
          tracks.forEach((track) => {
            track.stop()
          })
        }
      },
      capture() {
        const FLASH_TIMEOUT = 50
        let self = this
        setTimeout(() => {
          const context = self.$refs.canvas.getContext('2d')
          context.drawImage(self.$refs.camera, 0, 0, this.canvasWidth, this.canvasHeight)
          const dataUrl = self.$refs.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
          self.isCameraOpen = false
          self.stopCameraStream()
        }, FLASH_TIMEOUT)
      },
      async checkTwoConfirm(e) {
        if (e?.target?.files[0]) {
          const arquivo = e.target.files[0].name.toLowerCase()
          const extensoesPermitidas = ['.jpeg', '.jpg', '.heic', '.png']
          const extensao = arquivo.substr(arquivo.lastIndexOf('.'))

          if (!extensoesPermitidas.includes(extensao)) {
            this.$message.info('Extensão inválida! Só é aceito fotos no formato: .jpeg, .heic, .jpg ou .png')
            return
          }
        }

        let self = this
        const dataUrl = self.$refs.canvas.toDataURL('image/png').replace('image/png', 'base64')
        var file = this.dataURLtoFile(dataUrl, 'image.png')
        this.getFileDoc(file, true)
        this.$refs['modal-foto'].hide()
      },
      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, { type: 'image/png' })
      },
    },
    watch: {
      tiposDocumentosFiltrados(novo, atual) {
        if (this.documentsListIsZero()) {
          this.$emit('documentoValidado')
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .repeater-form {
    overflow: hidden;
    transition: 0.35s height;
  }

  .disabled {
    background-color: lightgray;
    padding: 0.7rem;
    border-radius: 0.3rem;
    font-weight: 500;
    border: 1px solid lightgray;
  }

  body.dark-layout .disabled {
    background-color: rgba(0, 0, 0, 0);
    padding: 0.7rem;
    border-radius: 0.3rem;
    font-weight: 500;
    border: 1px solid lightgray;
  }

  .spinner {
    width: 56px;
    height: 56px;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: #006ac0;
    animation: tri-spinner 1s infinite linear;
  }

  .spinner::before,
  .spinner::after {
    content: '';
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: tri-spinner 2s infinite;
  }

  .spinner::after {
    margin: 8px;
    animation-duration: 3s;
  }

  @keyframes tri-spinner {
    100% {
      transform: rotate(1turn);
    }
  }
</style>
